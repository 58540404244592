import { useContext } from 'react'
import { NotificationsContext } from 'context/notifications/toastContext'
import useApi from 'services/api/fetchData'
import { AppRoutes } from 'config'
import { useQueryClient } from '@tanstack/react-query'

export const useCreateVisitTimesheet = () => {
	const { showError, showSuccess } = useContext(NotificationsContext)
	const { putRequest } = useApi()
	const queryClient = useQueryClient()

	const createVisitTimesheet = async (data: any) => {
		try {
			for (const timesheet of data) {
				await putRequest(
					AppRoutes.serverVisitTimesheetRoutes.createVisitTimesheet,
					timesheet,
				)
			}
			showSuccess('Visit timesheet updated successfully')
			queryClient.invalidateQueries(['visit_timesheets'])
		} catch (error) {
			showError('Something went wrong when updating the visit timesheet')
			throw new Error('Something went wrong updating visit timesheet')
		}
	}

	return { createVisitTimesheet }
}
