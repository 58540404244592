import { LockClosedIcon, LockOpenIcon } from '@heroicons/react/24/outline'
import { Dropdown, Input, SideModal, Spinner, TextArea } from 'common'
import { useFormik } from 'formik'
import { useEffect, useState } from 'react'
import { InvoiceServices, JobsServices, QuoteServices } from 'services'
import { useCreateEDInvoice } from 'services/invoices'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import * as Yup from 'yup'

interface IProps {
	invoice_id?: number
	heading: string
	setOpen: (open: boolean) => void
	formType: 'create' | 'update'
	open: boolean
	total: number
	hours: number
	isDayworks: boolean
	job_id: number
}

interface IinitialValues {
	zone: string
	zone_label: string
	type: string
	description: string
	amount_to_invoice: number
	invoice_amount: number
	invoiced: number
	total: number
	PO_Number: string
	hourly_rate: number
	total_hours: number
	task_id: string
}
export const EdInvoiceEditForm = ({
	invoice_id,
	heading,
	setOpen,
	formType,
	open,
	total,
	hours,
	isDayworks,
	job_id,
}: IProps) => {
	const { data: invoiceData, isLoading: invoiceLoading } =
		InvoiceServices.useEdInvoiceById(invoice_id)
	const { data: TaskData, isLoading: taskIsLoading } = JobsServices.useJobTask(job_id)

	const { data: rates, isLoading } = QuoteServices.useServiceRates()

	const { updateEdInvoice } = InvoiceServices.useUpdateEDInvoice()
	const { createEdInvoice } = InvoiceServices.useCreateEDInvoice()

	const [totalLocked, setTotalLocked] = useState(false)

	const initialValues: IinitialValues = {
		amount_to_invoice: 0,
		invoice_amount: invoiceData?.invoice_amount || 0,
		invoiced: invoiceData?.invoiced || 0,
		total: invoiceData?.total || total,
		zone: invoiceData?.zone || '',
		zone_label: invoiceData?.zone_label || '',
		type: invoiceData?.type || '',
		description: invoiceData?.description || '',
		PO_Number: invoiceData?.PO_Number || '',
		hourly_rate: invoiceData?.hourly_rate || 0,
		total_hours: invoiceData?.total_hours || hours || 0,
		task_id: invoiceData?.task_id || '',
	}

	const validationSchema = Yup.object({
		task_id: Yup.number().required('Task is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (formType === 'update' && invoice_id) {
				if (
					values.amount_to_invoice < values.invoice_amount &&
					values.amount_to_invoice > 0
				) {
					const clonedInvoice = {
						description: invoiceData.description,
						invoice_amount:
							invoiceData.invoice_amount - values.amount_to_invoice,
						job_id: invoiceData.job_id,
						last_invoice: invoiceData.last_invoice,
						last_time_updated: invoiceData.last_time_updated,
						PO_Number: invoiceData.PO_Number,
						invoiced: invoiceData.invoiced + values.amount_to_invoice,
						status: invoiceData.status,
						task_id: Number(values.task_id),
						type: invoiceData.type,
						zone: invoiceData.zone,
						zone_label: invoiceData.zone_label,
						complete_percent: invoiceData.complete_percent,
						total: invoiceData.total,
						Quote_Number: invoiceData.Quote_Number,
						xeroReference: invoiceData.xeroReference,
						hourly_rate: invoiceData.hourly_rate,
						total_hours: invoiceData.total_hours,
					}
					await createEdInvoice(invoiceData?.job_id, clonedInvoice)
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						invoice_amount: values.amount_to_invoice,
						PO_Number: values.PO_Number,
						description: values.description,
						total: values.total,
					})
				} else {
					await updateEdInvoice(invoice_id, {
						...invoiceData,
						invoice_amount: values.invoice_amount,
						PO_Number: values.PO_Number,
						description: values.description,
						total: values.total,
						zone: values.zone,
						zone_label: values.zone_label,
						type: values.type,
						hourly_rate: values.hourly_rate,
						total_hours: values.total_hours,
						task_id: Number(values.task_id),
					})
				}
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		if (isDayworks && invoiceData?.type !== 'Inspection') {
			const handleTotalCalculation = () => {
				if (!totalLocked) {
					const total = formik.values.total_hours * formik.values.hourly_rate
					formik.setFieldValue('total', Number(total).toFixed(2))
				}
			}
	
			handleTotalCalculation()
		}
	}, [formik.values.total_hours, formik.values.hourly_rate, totalLocked])

	useEffect(() => {
		if (formik.values.type) {
			const type = rates?.find(
				(rate: any) => rate.service === formik.values.type
			)
			if (type) {
				formik.setFieldValue('hourly_rate', type.fee)
				if (type.service === 'Inspection') {
					formik.setFieldValue('zone', 99)
					formik.setFieldValue('zone_label', 'Additional Items')
				}
			}
		}
	}, [formik.values.type, rates])

	useEffect(() => {
		if (formik.values.task_id && !taskIsLoading) {
			const task = TaskData.find(
				(task: { id: string }) =>
					task.id === String(formik.values.task_id)
			)
			formik.setFieldValue('description', task.description)
			if (formik.values.type !== 'Inspection') {
				formik.setFieldValue('zone', task.zone)
				formik.setFieldValue('zone_label', task.zone_label)
			}
		}
	}, [formik.values.task_id, taskIsLoading])

	const handleTotalBlur = (event: any) => {
		let value = event.target.value

		if (!isNaN(value) && value !== '') {
			value = parseFloat(value).toFixed(2)
			formik.setFieldValue('total', value)
		}
	}

	if (invoice_id && (invoiceLoading || taskIsLoading || isLoading)) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={formType}>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Dropdown
							id="task_id"
							label="Task"
							options={OptionsForDropdownFilter(
								TaskData,
								'id',
								['zone_label', 'type', 'description'],
								'job_id',
								job_id!
							)}
							onChange={formik.setFieldValue}
							value={String(formik.values.task_id)}
							error={formik.errors.task_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Section"
							id="zone_label"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Section"
							value={formik.values.zone_label}
							error={formik.errors.zone_label}
							disabled
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="type"
							label="Type"
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							value={formik.values.type}
							error={formik.errors.type}
							options={OptionsForDropdown(rates, 'service', 'service')}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="PO Number"
							id="PO_Number"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="e.g. PO-1234"
							value={formik.values.PO_Number}
							error={formik.errors.PO_Number}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2 h-40">
					<div className="w-full ">
						<TextArea
							title="Description"
							id="description"
							type="text"
							className=" resize-none"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Description"
							rows={5}
							value={formik.values.description}
							error={formik.errors.description}
							// disabled
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Hourly Rate"
							id="hourly_rate"
							type="text"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Hourly Rate"
							value={formik.values.hourly_rate}
							error={formik.errors.hourly_rate}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="Total Hours"
							id="total_hours"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Total Hours"
							value={formik.values.total_hours}
							error={formik.errors.total_hours}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							title="Amount to Invoice"
							id="amount_to_invoice"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="e.g 1000"
							value={formik.values.amount_to_invoice}
							error={formik.errors.amount_to_invoice}
						/>
					</div>
					<div className="w-1/2">
						<Input
							id="invoice_amount"
							title="Invoice Amount"
							placeholder="invoice_amount"
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.invoice_amount}
							error={formik.errors.invoice_amount}
							disabled
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Input
							id="invoiced"
							title="Previously Invoiced"
							placeholder=""
							type="number"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							value={formik.values.invoiced}
							error={formik.errors.invoiced}
							disabled
						/>
					</div>
					<div className="w-1/2 flex items-end">
						<Input
							title="Total Invoiceable"
							id="total"
							type="number"
							handleBlur={handleTotalBlur}
							handleChange={formik.handleChange}
							placeholder="Total"
							value={formik.values.total}
							error={formik.errors.total}
							disabled={!totalLocked}
						/>
						<div>
							<button
								type="button"
								onClick={() => setTotalLocked(!totalLocked)}
								className="ml-2 p-2 mb-2 border rounded-full hover:bg-gray-200 flex items-center justify-center">
								{totalLocked ? (
									<LockClosedIcon className="h-5 w-5 text-gray-700" />
								) : (
									<LockOpenIcon className="h-5 w-5 text-gray-700" />
								)}
							</button>
						</div>
					</div>
				</div>
			</SideModal>
		</>
	)
}
