import { PencilIcon, TrashIcon } from '@heroicons/react/24/solid'
import { Button, ConfirmationDialog, Spinner, Table } from 'common'
import { AppRoutes } from 'config'
import moment from 'moment'
import { useMemo } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { JobsServices, ScaffoldRegisterServices, VisitServices } from 'services'
interface JobTaskTableProps {
	job_id?: number
	client_id?: number
}

export const JobTaskTable = ({ job_id, client_id }: JobTaskTableProps) => {
	const location = useLocation()
	const { data, isLoading, enableCreateUpdate } =
		JobsServices.useJobTask(job_id)
	const { deleteTask } = JobsServices.useDeleteJobTask()
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisitsByJobId(job_id)
	const { data: scaffoldData, isLoading: scaffoldLoading } =
		ScaffoldRegisterServices.useGetTagByJobId(job_id)

	const preprocessedData = useMemo(() => {
		return data?.map((task: any) => {
			const scaffold = scaffoldData?.find(
				(scaffold: { task_id: any }) => scaffold.task_id == task.id
			)

			let url = ''
			if (scaffold) {
				const handoverCert = scaffold.appEntries.find(
					(appEntry: { file_type: string }) =>
						appEntry.file_type === 'Handover Certificate'
				)
				url = handoverCert?.link
			}

			return {
				...task,
				handover_url: url,
			}
		})
	}, [data, scaffoldData])

	const calcHours = (row: any) => {
		let totalHours = 0
		if (visitsData) {
			for (const visit of visitsData) {
				const start = moment(visit.time_on, 'HH:mm')
				const finish = moment(visit.time_off, 'HH:mm')

				if (
					start.isValid() &&
					finish.isValid() &&
					visit.task_ids.includes(row.id)
				) {
					const duration = moment.duration(finish.diff(start))
					const hours = duration.asHours()
					totalHours += hours
				}
			}
		}
		return totalHours
	}

	if (isLoading || visitsLoading || scaffoldLoading) {
		return <Spinner />
	}

	const columns = [
		{ field: 'zone', header: 'Section' },
		{ field: 'zone_label', header: 'Section Label' },
		{ field: 'type', header: 'Service Type' },
		{ field: 'description', header: 'Description' },
		// { field: 'total_hours', header: 'Quoted Hours' },
		// {
		// 	field: '',
		// 	header: 'Actual Hours',
		// 	body: (row: any) => {
		// 		return <div>{calcHours(row)}</div>
		// 	},
		// },
		{ field: 'quoted_square_meters', header: 'Quoted SQM' },
		{ field: 'actual_square_meters', header: 'Installed SQM' },
		{ field: 'actual_square_metres_dismantled', header: 'Dismantled SQM' },
		{ field: 'PO_Number', header: 'PO Number' },
		// { field: 'percentage_erect', header: '% Erect' },
		// { field: 'percentage_dismantle', header: '% Dismantle' },
		// { field: 'percentage_complete', header: '% Completed' },
		{
			field: 'handover_certificate',
			header: 'Handover Certificate',
			body: (row: { handover_url: string }) => {
				if (row.handover_url) {
					return (
						<a href={row.handover_url} target="_blank" rel="noreferrer">
							Link
						</a>
					)
				}
				return <></>
			},
		},
		{ field: 'complete', header: 'Completed' },
		{
			field: 'quote_id',
			header: 'Quote',
			body: (row: { quote_id: number; quoteData: { quote_num: string } }) => (
				<Link
					to={AppRoutes.privateRoutes.QuotesPDF.replace(
						':id',
						row.quote_id?.toString()
					)}>
					{row.quoteData?.quote_num}
				</Link>
			),
		},
		{
			field: 'Edit',
			header: 'Edit',
			body: (row: { id: number }) =>
				enableCreateUpdate ? (
					<Link
						to={{
							pathname: AppRoutes.privateRoutes.tasksEdit.replace(
								':id',
								row.id.toString()
							),
						}}
						state={{ background: location, name: 'editTask', client_id }}>
						<PencilIcon className="h-4 w-4 text-gray-500" />
					</Link>
				) : (
					<></>
				),
		},
		{
			field: 'Delete',
			header: 'Delete',
			body: (row: { id: number }) =>
				enableCreateUpdate ? (
					<ConfirmationDialog
						icon="danger"
						title="Delete Task"
						body="Are you sure you want to delete this task? This action is unrecoverable!"
						triggerButton={
							<button type="button">
								<TrashIcon className="h-4 w-4 text-gray-500" />
							</button>
						}
						confirmButton={
							<Button
								size="sm"
								variant="danger"
								onClick={async () => deleteTask(row.id)}>
								Delete
							</Button>
						}
					/>
				) : (
					<></>
				),
		},
	]

	return (
		<>
			<Table
				columns={columns}
				data={preprocessedData.filter(
					(task: { task_type: string }) => task.task_type === 'Task'
				)}
				isLoading={isLoading ?? false}
				title="Job Task"
				disableButtons
			/>
		</>
	)
}
