import { PlusCircleIcon } from '@heroicons/react/24/solid'
import clsx from 'clsx'
import { Dropdown, Input, SearchAddress, Spinner, TextArea } from 'common'
import { CreateClientsForm } from 'components/Clients'
import { ContacsForm } from 'components/Contacts'
import { ClientServices, ContactServices, StaffServices } from 'services'
import { OptionsForDropdown, OptionsForDropdownFilter } from 'utilities'
import { FormikProps } from 'formik'
import { IQuoteForm } from 'models/simple_quotes.model'
import { AppStore } from 'redux/store'
import { useSelector } from 'react-redux'
import { useEffect, useMemo, useState } from 'react'
import { IStaffRow } from 'models/staff.model'
import { branchOptions } from 'models'

const quoteTypeOptions = [
	{ value: 'New', label: 'New' },
	{
		value: 'Variation',
		label: 'Variation',
	},
]

interface FormProps {
	formik?: FormikProps<IQuoteForm>
	jobs_data?: object[]
	clientsDataHook: {
		data: any;
		isLoading: boolean;
		error: unknown;
	}
	clientContactsDataHook: {
		data: any;
		isLoading: boolean;
		error: unknown;
	}
	staffDataHook: {
		data: IStaffRow[] | undefined;
		isLoading: boolean;
		error: unknown;
		enableCreateUpdate: boolean;
	}
}

const quoteZonesOptions = [
	{ value: '1', label: '1' },
	{ value: '2', label: '2' },
	{ value: '3', label: '3' },
	{ value: '4', label: '4' },
	{ value: '5', label: '5' },
	{ value: '6', label: '6' },
	{ value: '7', label: '7' },
	{ value: '8', label: '8' },
	{ value: '9', label: '9' },
	{ value: '10', label: '10' },
	{ value: '11', label: '11' },
	{ value: '12', label: '12' },
	{ value: '13', label: '13' },
	{ value: '14', label: '14' },
	{ value: '15', label: '15' },
	{ value: '16', label: '16' },
]

const jobTypeOptions = [
	{
		value: 'Residential',
		label: 'Residential',
	},
	{
		value: 'Confined Spaces',
		label: 'Confined Spaces',
	},
	{
		value: 'Commercial',
		label: 'Commercial',
	},
	{
		value: 'Marine',
		label: 'Marine',
	},
	{
		value: 'Industrial',
		label: 'Industrial',
	},
]

export const GeneralInfo = ({ formik, jobs_data, clientsDataHook, clientContactsDataHook, staffDataHook}: FormProps) => {
	const [clientForm, setClientForm] = useState(false)
	const [contactForm, setContactForm] = useState(false)
	// const [clientAddress, setClientAddress] = useState('')

	if (!formik) {
		return null
	}

	const preprocessedJobData = useMemo(() => {
		return jobs_data
			?.sort((a: any, b: any) => b.id - a.id)
			?.map((job: any) => {
				return {
					...job,
					job_display: `${job.job_num} - ${job.client_name} - ${job.descriptionOfQuote}`,
				}
			})
	}, [jobs_data])

	useEffect(() => {
		const job = preprocessedJobData?.find(
			(job: any) => job.id === formik.values.variation_job_id
		)
		if (job) {
			formik.setFieldValue('client', Number(job?.client_id))
			formik.setFieldValue('client_contact', Number(job?.client_contact))
		}
	}, [formik.values.variation_job_id])

	// TODO: Revisit function to automatically add client's address
	// useEffect(() => {
	// 	const client = clientsDataHook.data?.find(
	// 		(client: any) => client.id === formik.values.client
	// 	)
	// 	if (client) {
	// 		setClientAddress(client.site)
	// 	}
	// }, [formik.values.client])

	if(clientsDataHook.isLoading || clientContactsDataHook.isLoading || staffDataHook.isLoading){
		return <Spinner />
	}

	return (
		<>
			<div className="flex">
				{/*Quote Type Input */}
				<div className="w-1/2">
					<Dropdown
						label="New / Variation"
						id="quote_type"
						value={formik.values.quote_type}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						options={quoteTypeOptions}
						error={formik.errors.quote_type}
					/>
				</div>
				<div className="w-1/2">
					<Dropdown
						label="Branch"
						id="branch"
						options={branchOptions}
						value={formik.values.branch}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
					/>
				</div>
			</div>

			{formik.values.quote_type === 'Variation' && (
				<div className="flex">
					<div className="w-1/2">
						<Dropdown
							label="Job"
							id="variation_job_id"
							value={`${formik.values.variation_job_id}`}
							onChange={formik.setFieldValue}
							onBlur={formik.handleBlur}
							options={OptionsForDropdown(preprocessedJobData, 'id', 'job_display')}
							error={formik.errors.variation_job_id}
						/>
					</div>
					<div className="w-1/2">
						<Input
							title="PO Number"
							id="PO_Number"
							type="text"
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
							value={formik.values.PO_Number || ''}
							placeholder="PO Number"
							error={formik.errors.PO_Number}
						/>
					</div>
				</div>
			)}
			<div className="flex">
				{/*Quote Max Sections */}
				<div className="w-1/2">
					<Dropdown
						label="Number of Sections"
						id="max_zones"
						value={`${formik.values.max_zones}`}
						onChange={(id: string, value: string | number) =>
							formik.setFieldValue('max_zones', Number(value))
						}
						onBlur={formik.handleBlur}
						options={quoteZonesOptions}
						error={formik.errors.max_zones}
					/>
				</div>
				<div className="w-1/2">
					<Dropdown
						label="Job Type"
						id="job_type"
						value={`${formik.values.job_type}`}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						options={jobTypeOptions}
						error={formik.errors.job_type}
					/>
				</div>
			</div>
			{/*Clients Section */}
			<div className="flex">
				<div className="w-1/2">
					<Dropdown
						label="Client"
						id="client"
						isLoading={clientsDataHook.isLoading}
						value={formik.values.client || ''}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						options={OptionsForDropdown(
							clientsDataHook.data.filter((client: any) => client.status === 'Active'),
							'id',
							'client_name'
						)}
						error={formik.errors.client}
					/>
					<div className="flex items-center pl-4 -mt-3">
						<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
						<button
							type="button"
							className="pl-1 font-semibold leading-5 text-sm text-gray-600 hover:text-gray-800"
							onClick={() => setClientForm(true)}>
							Add New Client
						</button>
					</div>
				</div>
				<div className="w-1/2">
					<Dropdown
						label="Client Contact"
						id="client_contact"
						isLoading={clientContactsDataHook.isLoading}
						value={formik.values.client_contact || ''}
						onChange={formik.setFieldValue}
						onBlur={formik.handleBlur}
						disabled={formik.values.client === null}
						options={OptionsForDropdownFilter(
							clientContactsDataHook.data,
							'id',
							'name',
							'client_id',
							formik.values.client || ''
						)}
						error={formik.errors.client_contact}
					/>
					<div className="flex items-center pl-4 -mt-3">
						<PlusCircleIcon className="w-6 h-6 text-indigo-500" />
						<button
							type="button"
							className={clsx(
								formik.values.client === null
									? 'text-gray-200 cursor-none'
									: 'text-gray-600 hover:text-gray-800',
								'pl-1 font-semibold leading-5 text-sm'
							)}
							disabled={formik.values.client === null}
							onClick={() => setContactForm(true)}>
							Add New Contact
						</button>
					</div>
				</div>
			</div>
			<br />
			{/*Quote Number Section Only Renders in the Edit Mode but allways Disabled */}
			{formik.values.quote_num && (
				<div className="flex -ml-2">
					<div className="w-1/2">
						<Input
							title="Quote #"
							id="quote_num"
							handleBlur={formik.handleBlur}
							handleChange={formik.handleChange}
							placeholder="Quote Number"
							type="text"
							value={formik.values.quote_num || ''}
							disabled={true}
						/>
					</div>
				</div>
			)}
			{/*Quote Scope of Work */}
			<div className="flex">
				<div className="w-full">
					<TextArea
						title="Scope of Work"
						handleBlur={formik.handleBlur}
						handleChange={formik.handleChange}
						placeholder="Scope of Work"
						id="scope_of_work"
						value={formik.values.scope_of_work}
						error={formik.errors.scope_of_work}
						rows={5}
					/>
				</div>
			</div>
			{/*Quote Estimator */}
			<div className="flex">
				<div className="w-1/2">
					<Dropdown
						label="Estimator"
						id="estimator"
						value={formik.values.estimator || ''}
						onChange={formik.setFieldValue}
						error={formik.errors.estimator}
						onBlur={formik.handleBlur}
						options={OptionsForDropdown(staffDataHook.data?.filter((staffMember) => staffMember.status === 'Active'), 'id', 'staff_name')}
					/>
				</div>
			</div>
			<h3 className="text-lg px-2 leading-6 font-large">Site Address</h3>
			{/*Quote Site Address */}
			<SearchAddress
				streetId2="street2"
				streetId="street"
				cityId="city"
				postalId="postal"
				countryId="country"
				street2Val={formik.values.street2}
				countryVal={formik.values.country}
				cityVal={formik.values.city}
				postalVal={formik.values.postal}
				handleBlur={formik.handleBlur}
				setFieldValue={formik.setFieldValue}
				// clientAddress={clientAddress}
			/>
			<br />
			<CreateClientsForm
				open={clientForm}
				setOpen={setClientForm}
				heading="Create New Client"
				formType="create"
			/>
			<ContacsForm
				formType="create"
				heading="Create Contact"
				client_id={formik.values.client || undefined}
				open={contactForm}
				setOpen={setContactForm}
			/>
		</>
	)
}
