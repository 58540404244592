export const publicRoutes = {
	Login: '/login',
	SetPasswordReset: '/changePassword/:token',
	SetPassword: '/setPassword',
	ForgotPassword: '/forgot-password',
	PageNotFound: '*',
	AcceptQuote: '/accept-quote/:token',
}

export const privateRoutes = {
	Dashboard: '/dashboard',
	AppFiles: '/appFiles',
	Staff: '/staff',
	StaffDetail: '/staff/:id/details',
	StaffEdit: '/staff/:id/edit',
	StaffCompetencies: '/staffCompetencies',
	Clients: '/clients',
	ClientsEdit: '/clients/:id/edit',
	ClientsDetail: '/clients/:id/details',
	ClientContactEdit: '/contact/:id/edit',
	ClientAddressEdit: '/addresses/:id/edit',
	Quotes: '/quotes',
	QuotesCreate: '/quotes/create',
	QuotesEdit: '/quotes/:id/edit',
	QuotesDetail: '/quotes/:id/details',
	QuotesPDF: '/quotes/:id/output',
	Vehicles: '/vehicles',
	VehiclesEdit: '/vehicles/:id/edit',
	VehiclesDetail: '/vehicles/:id/details',
	Assets: '/assets',
	AssetsEdit: '/assets/:id/edit',
	AssetsDetail: '/assets/:id/details',
	Jobs: '/jobs',
	JobEdit: '/jobs/:id/edit',
	JobDetails: '/jobs/:id/details',
	JobScheduler: '/job-calendar',
	JobStaffScheduler: '/team-calendar',
	EventScheduler: '/event-calendar',
	tasksEdit: '/jobs/tasks/:id/edit',
	variationTasksEdit: '/jobs/variationTasks/:id/edit',
	Invoices: '/invoices',
	InvoicesApproved: '/ApprovedInvoices',
	editInvoice: '/invoices/:invoiceType/:id/edit',
	visits: '/visits',
	dailySummary: '/daily-summary',
	visitsEdit: '/visits/:id/edit',
	Timesheets: '/timesheets',
	visitTimesheets: '/visit-timesheets',
	visitTimesheetsEdit: '/visit-timesheets/:id/edit',
	TimesheetsApproved: '/ApprovedTimesheets',
	TimesheetsEdit: '/timesheets/:id/edit',
	TimesheetDetails: '/timesheets/:id/details',
	scaffoldRegister: '/scaffold-register',
	scaffoldRegisterEdit: '/scaffold-register/:id/edit',
	scaffoldRegisterDetail: '/scaffold-register/:id/details',
	leave: '/leave',
	leaveEdit: '/leave/:id/edit',
	LeaveApproved: '/ApprovedLeave',
	InvestigationReports: '/investigationReports',
	Support: '/support',
	SupportTicketEdit: '/support/:id/edit',
}

export const serverAppFilesRoutes = {
	getAllAppFiles: 'app-files',
	getAppFile: 'app-files/:id',
	createAppFile: 'app-files',
	updateAppFile: 'app-files/:id',
	getAllAppFilesVehicles: 'app-files/vehicle/:id',
	getAppFilesJobs: 'app-files/job/:id',
	getAppFilesStaff: 'app-files/staff/:id',
	getAppFilesScaffoldRegister: 'app-files/scaffold-register/:id',
	getAppFilesClients: 'app-files/clients/:id',
}

export const serverNotesAndFilesRoutes = {
	getAllNotesAndFiles: 'notes',
	getNotesAndFiles: 'notes/:id',
	createNotesAndFiles: 'notes',
	updateNotesAndFiles: 'notes/:id',
	getAllNotesAndFilesByVehicleId: 'notes/vehicle/:id',
	getAllNotesAndFilesByJobId: 'notes/job/:id',
	getAllNotesAndFilesByStaffId: 'notes/staff/:id',
	getAllNotesAndFilesByAssetsId: 'notes/assets/:id',
	getAllNotesAndFilesByClientsId: 'notes/client/:id',
	getAllNotesAndFilesByScaffoldRegisterId: 'notes/scaffold-register/:id',
}

export const serverEventsRoutes = {
	getEvents: 'events',
	getEvent: 'events/:id',
	createEvent: 'events',
	updateEvent: 'events/:id',
}

export const serverAuthRoutes = {
	checkEnableConfig: 'enableConfig',
	setUpServer: 'setupServer',
	login: 'auth/login',
	logout: 'auth/logout',
	resetPasswordVerifyToken: 'auth/check-token-reset-password',
	resetPassword: 'auth/reset-password',
	requestResetPassword: 'auth/request-password-reset',
}

export const serverIncidentReportsRoutes = {
	getAllIncidentReports: 'investigation-report',
	getIncidentReports: 'investigation-report/:id',
	createIncidentReports: 'investigation-report',
	updateIncidentReports: 'investigation-report/:id',
}

export const serverStaffRoutes = {
	getAllStaff: 'staff',
	getStaff: 'staff/:id',
	createStaff: 'staff',
	updateStaff: 'staff/:id',
	sendInvite: 'staff/:id/send_invitation'
}

export const serverJobsRoutes = {
	getAllJobs: 'jobs',
	getAllJobHandovers: 'jobs/handovers',
	getJob: 'jobs/:id',
	createJob: 'jobs',
	updateJob: 'jobs/:id',
	getJobTasks: 'jobs/:id/tasks',
	getTask: 'jobs/job/tasks/:id',
	getAllTask: 'jobs/all/tasks/all',
	createVariationTask: 'jobs/:id/tasks/variation',
	createTask: 'jobs/:id/tasks',
	deleteTask: 'jobs/job/tasks/:id',
	updateTask: 'jobs/job/tasks/:id',
	getHandoverByJobId: 'jobs/:id/handover',
	updateHandover: 'jobs/job/handover/:id',
}

export const serverScaffoldRegisterRoutes = {
	getAllScaffoldRegister: 'scaffold-register',
	getAllScaffoldRegisterByJobId: 'scaffold-register/job/:id',
	getScaffoldRegister: 'scaffold-register/:id',
	createScaffoldRegister: 'scaffold-register',
	updateScaffoldRegister: 'scaffold-register/:id',
}

export const serverClientRoutes = {
	getAllClients: 'clients',
	getClient: 'clients/:id',
	createClient: 'clients',
	updateClient: 'clients/:id',
}

export const serverAddressRoutes = {
	updateClientAddress: 'addresses/:id',
	createClientAddress: 'addresses',
	getAddressesByClientID: 'clients/:id/addresses',
	getAddresses: 'addresses',
	getAddressById: 'addresses/:id',
}

export const serverContactRoutes = {
	getAllContacts: 'contacts',
	getContactsByClientID: 'clients/:id/contacts',
	getContact: 'contacts/:id',
	createContact: 'contacts',
	updateContact: 'contacts/:id',
}

export const serverQuoteRoutes = {
	getAllQuotes: 'quotes',
	getQuote: 'quotes/id/:id',
	getQuoteByToken: 'quotes/token/:id', // It's a token not an id
	createQuote: 'quotes',
	updateQuote: 'quotes/id/:id',
	getServiceRates: 'service_rates',
	deleteServiceRatesById: 'service_rates/:id',
	deleteQuoteRatesById: 'quotes/rates/:id',
	getQuoteLinesById: 'quotes/id/:id/lines',
	getQuoteAddonsById: 'quotes/id/:id/addons',
	getQuoteZonesById: 'quotes/id/:id/zones',
	getQuoteRatesById: 'quotes/id/:id/rates',
	getQuoteFilesById: 'quotes/id/:id/files',
	getQuoteLinesByToken: 'quotes/token/:id/lines',
	getQuoteAddonsByToken: 'quotes/token/:id/addons',
	getQuoteZonesByToken: 'quotes/token/:id/zones',
	getQuoteRatesByToken: 'quotes/token/:id/rates',
	getQuoteFilesByToken: 'quotes/token/:id/files',
	updatedAcceptByToken: 'quotes/token/:id/updateAccept',
	deleteQuoteFileByQuoteFileId: 'quotes/files/:id',
	sendQuoteEmail: 'quotes/id/:id/sendQuoteEmail',
	sendQuoteEmailTemplate: 'quotes/id/:id/sendQuoteTemplateEmail',
	markAsPendingEmail: 'quotes/id/:id/markPendingEmail',
	declineQuote: 'quotes/id/:id/decline',
	approveQuote: 'quotes/id/:id/approve',
}

export const serverInvoiceRoutes = {
	getAllInvoices: 'invoices',
	getEDInvoicesByJobId: 'invoices/edinvoice/job/:id',
	getWeeklyInvoicesByJobId: 'invoices/weeklyHire/job/:id',
	getEdinvoice: 'invoices/edinvoice/:id',
	getWeeklyInvoice: 'invoices/weeklyHire/:id',
	createEdinvoice: 'invoices/edinvoice/job/:id',
	createWeeklyInvoice: 'invoices/weeklyHire/job/:id',
	updateEdinvoice: 'invoices/edinvoice/:id',
	updateWeeklyInvoice: 'invoices/weeklyHire/:id',
	deleteEdinvoice: 'invoices/edinvoice/:id',
	deleteWeeklyInvoice: 'invoices/weeklyHire/:id',
	approveInvoices: 'invoices/approve',
}

export const serverVisitRoutes = {
	getAllVisits: 'visits',
	getAllCompletedVisits: 'visits/completed',
	getVisit: 'visits/:id',
	createVisit: 'visits',
	updateVisit: 'visits/:id',
	getVisitByJobId: 'visits/job/:id',
}

export const serverTimesheetRoutes = {
	getAllTimesheets: 'timesheets/all',
	getAllTimesheetsByStaffId: 'timesheets/staff/:id',
	getAllTimesheetsByJobId: 'timesheets/job/:id',
	getAllTimesheetsByStatus: 'timesheets/status/:id',
	getTimesheet: 'timesheets/:id',
	createTimesheet: 'timesheets/create',
	updateTimesheet: 'timesheets/:id',
	submitTimesheetsToXero: 'timesheets/submitToXero',
}

export const serverVisitTimesheetRoutes = {
	getAllVisitTimesheets: 'visit_timesheets',
	getAllVisitTimesheetsByJobId: 'visit_timesheets/job/:id',
	getAllVisitTimesheetsById: 'visit_timesheets/id/:id',
	createVisitTimesheet: 'visit_timesheets',
	updateVisitTimesheet: 'visit_timesheets/id/:id',
}

export const serverLeavesRoutes = {
	getAllLeaves: 'leaves',
	getAllApprovedLeaves: 'leaves/approved',
	getLeave: 'leaves/:id',
	createLeave: 'leaves',
	updateLeave: 'leaves/:id',
	getLeaveByStaffId: 'leaves/staff/:id',
	getAllLeavesByStaffId: 'leaves/staff/:id',
}

export const serverVehicleRoutes = {
	getAllVehicles: 'vehicles',
	getVehicle: 'vehicles/:id',
	createVehicle: 'vehicles',
	updateVehicle: 'vehicles/:id',
}

export const serverAssetsRoutes = {
	getAllAssets: 'assets',
	getAsset: 'assets/:id',
	createAsset: 'assets',
	updateAsset: 'assets/:id',
	getAllAssetCategories: 'asset_categories',
	getAssetTypes: 'asset_types/:id',
}

export const serverFilesRoutes = {
	getAllFiles: 'files',
	getFile: 'files/:id',
	createFile: 'files',
	updateFile: 'files/:id',
}

export const serverSetUpRoute = 'setUpServer'

export const serverSupportTicketRoutes = {
	getAllSupportTickets: 'support/',
	getAllSupportTicketsByStatus: 'timesheets/status/:id',
	getSupportTicket: 'support/:id',
	createSupportTicket: 'support/',
	updateSupportTicket: 'support/:id',
}
