import { useEffect, useState } from 'react'
import { DataTable, DataTableDataSelectableEvent } from 'primereact/datatable'
import {
	TimesheetServices,
	DataTableServices,
	VisitTimesheetsServices,
} from 'services'
import { Badge, Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveTimesheet } from 'components/Timesheets'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { EditTimesheet } from 'components/Timesheets/EditForm'
import { Link } from 'react-router-dom'
import { AppRoutes } from 'config'
import { ClipboardIcon, PencilIcon } from '@heroicons/react/24/outline'
import { CreateTimesheet } from 'components/Timesheets/CreateForm'
import { XMarkIcon } from '@heroicons/react/24/solid'
import { isEmpty } from 'utilities'
import { XeroStatus } from 'components/Invoices/XeroStatus'

export const TimesheetsPage = () => {
	const [timesheetsSelected, setTimesheetsSelected] = useState([])
	const [openTimesheetCreateForm, setOpenTimesheetCreateForm] = useState(false)
	const [openTimesheetEditForm, setOpenTimesheetEditForm] = useState(false)
	const [timesheetId, setTimesheetId] = useState(null)
	const { data, isLoading } = TimesheetServices.useTimesheetsByStatus('Pending')
	const { updateTimesheet } = TimesheetServices.useUpdateTimesheetById()
	const { data: visitTimesheets, isLoading: visitTimesheetsLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	const deleteTimesheet = async (timesheetId: number) => {
		await updateTimesheet({ status: 'Pending - Deleted' }, timesheetId)
	}

	useEffect(() => {
		if (openTimesheetCreateForm) {
			setOpenTimesheetEditForm(false)
		}
		if (openTimesheetEditForm) {
			setOpenTimesheetCreateForm(false)
		}
	}, [openTimesheetCreateForm, openTimesheetEditForm])

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'status',
				filterInitialValue: 'Pending',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: ['Pending'],
			},
			{
				filterName: 'formattedDate',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.DATE_IS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
				typeFilterOperator: FilterOperator.AND,
			},
		],
		additionalGlobalFilterFields: [
			'staffData.staff_name',
			'date',
			'comments',
			'status',
			'actual_start',
			'time_on',
			'actual_finish',
			'time_off',
			'total_hours',
			'lunch_break',
		],
	})

	function calcHours(
		startTime: string,
		finishTime: string,
		lunchBreak: number
	) {
		const start = moment(startTime, 'HH:mm')
		const finish = moment(finishTime, 'HH:mm')

		if (finishTime && finish) {
			const duration = moment.duration(finish.diff(start))
			const hours = duration.asHours()
			if (hours < 5) {
				return hours // No lunch break for less than 5 hours
			}
			return hours - lunchBreak / 60
		}
		return null
	}

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
	}) => {
		let total = 0

		if (data) {
			for (const timesheet of data) {
				if (
					Number(timesheet.staff_id) === row.staff_id &&
					timesheet.status === 'Pending'
				) {
					const hours = timesheet.time_off
						? Number(
								calcHours(
									timesheet.time_on,
									timesheet.time_off,
									timesheet.lunch_break
								)
						  )
						: 0
					total += hours
				}
			}
		}
		return total
	}

	const checkBillableHours = (row: {
		comments: string
		time_off: string
		time_on: string
		lunch_break: number
		staff_id: any
		date: any
	}) => {
		if (row.comments.includes('Leave:')) {
			return 'bg-green-200'
		}

		if (!row.time_off) {
			return 'bg-orange-200'
		}

		const totalHours = calcHours(row.time_on, row.time_off, row.lunch_break)

		const filteredVisitTimesheets = visitTimesheets?.filter(
			(timesheet: { status: string; staff_id: any; date: any }) =>
				String(timesheet?.staff_id) === String(row?.staff_id) &&
				timesheet.date === row?.date &&
				timesheet?.status !== 'Deleted' &&
				timesheet?.status !== 'Inactive'
		)

		if (!isEmpty(filteredVisitTimesheets)) {
			const sortedTimeSheetsByTimeIn = filteredVisitTimesheets?.sort(
				(a: any, b: any) => {
					return moment(a.job_start_time, 'HH:mm').diff(
						moment(b.job_start_time, 'HH:mm')
					)
				}
			)

			const mappedVisitTimesheets = sortedTimeSheetsByTimeIn.map(
				(timesheet: { job_start_time: any; time_in: any }, index: number) => {
					if (!timesheet.job_start_time) {
						if (index === 0) {
							timesheet.job_start_time = row.time_on || timesheet.time_in
						} else {
							timesheet.job_start_time =
								sortedTimeSheetsByTimeIn[index - 1].time_off
						}
					}
					return { ...timesheet }
				}
			)

			if (mappedVisitTimesheets.length > 0 && row.time_off) {
				mappedVisitTimesheets[mappedVisitTimesheets.length - 1].time_off =
					row.time_off
			}

			const areVisitTimesheetsApproved = mappedVisitTimesheets.every(
				(timesheet: { status: string }) => timesheet.status === 'Approved'
			)

			const timesheetData = mappedVisitTimesheets.map((timesheet: any) => {
				const startTime = timesheet.job_start_time
					? moment(timesheet.job_start_time, 'HH:mm')
					: moment(timesheet.time_in, 'HH:mm')
				const endTime = moment(timesheet.time_off, 'HH:mm')
				return { startTime, endTime }
			})

			const startTime = timesheetData[0].startTime
			const endTime = timesheetData[timesheetData.length - 1].endTime

			const visitTotalHours = calcHours(startTime, endTime, row.lunch_break)

			return totalHours === visitTotalHours && areVisitTimesheetsApproved
				? 'bg-green-200'
				: 'bg-orange-200'
		} else {
			return 'bg-orange-200'
		}
	}

	const headerTemplate = (data: {
		staffData: {
			staff_name: string
		}
	}) => (
		<span className="text-gray-900 font-bold">{data.staffData.staff_name}</span>
	)

	const footerTemplate = (data: any) => (
		<>
			<td
				colSpan={7}
				style={{ textAlign: 'right' }}
				className="bg-gray-100 font-normal">
				Total Hours
			</td>
			<td colSpan={1} className="bg-gray-100 font-semibold">
				{calculateTimesheetTotal(data)}
			</td>
		</>
	)

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	const isSelectable = (data: {
		comments: string
		time_off: string
		staff_id: any
		date: any
	}) => {
		if (data.comments.includes('Leave:')) {
			return true
		}

		const filteredVisitTimesheets = visitTimesheets?.filter(
			(timesheet: { staff_id: number; date: string; status: string }) =>
				String(timesheet.staff_id) === String(data.staff_id) &&
				timesheet.date === data.date &&
				timesheet.status !== 'Deleted' &&
				timesheet.status !== 'Inactive'
		)

		if (filteredVisitTimesheets && filteredVisitTimesheets.length > 0) {
			const areVisitTimesheetsApproved = filteredVisitTimesheets.every(
				(timesheet: { status: string }) => timesheet.status === 'Approved'
			)
			return Boolean(data.time_off && areVisitTimesheetsApproved)
		}

		return false
	}

	const isRowSelectable = (event: any) =>
		event.data ? isSelectable(event.data) : true

	// const rowClassName = (data: { time_off: string }) =>
	// 	isSelectable(data) ? '' : 'p-disabled'

	if (isLoading) {
		return <Spinner />
	}

	const preprocessedData = data
		.sort((a: { date: string }, b: { date: string }) => {
			return (
				moment(b.date, 'DD/MM/YYYY').valueOf() -
				moment(a.date, 'DD/MM/YYYY').valueOf()
			)
		})
		.map((data: any) => {
			return {
				...data,
				total_hours: calcHours(data.time_on, data.time_off, data.lunch_break),
				formattedDate: moment(data?.date, 'DD/MM/YYYY').toDate(),
			}
		})

	console.log('timesheets', preprocessedData)

	return (
		<div className="card">
			<PageHeading
				title="Payroll Timesheets"
				createBtn={'Create Payroll Timesheet'}
				setOpen={setOpenTimesheetCreateForm}
			/>
			<Container>
				<div className="flex justify-between ml-4">
					<ApproveTimesheet timesheets={timesheetsSelected} />
				</div>
				<DataTable
					ref={dataTableReference}
					value={preprocessedData}
					loading={isLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="staffData.staff_name"
					rowGroupHeaderTemplate={headerTemplate}
					rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					sortField="staffData.staff_name"
					sortOrder={1}
					filters={filters}
					globalFilterFields={globalFilterFields}
					header={header}
					selection={timesheetsSelected}
					stripedRows
					isDataSelectable={isRowSelectable}
					onSelectionChange={(e) => setTimesheetsSelected(e.value as any)}
					emptyMessage="No Timesheets found.">
					<Column
						selectionMode="multiple"
						headerStyle={{ width: '3rem' }}
						bodyClassName={(rowData: any) => checkBillableHours(rowData)}
					/>
					<Column field="staffData.staff_name" header="Staff" />
					<Column
						header="Date"
						filterField="formattedDate"
						field="formattedDate"
						style={{ minWidth: '8rem' }}
						body={(row) => (
							<div>
								{row.date}
							</div>
						)}
						{...FilterColumn.formattedDate}
					/>
					<Column
						header="Actual Start"
						field="actual_start"
						filterField="time_on"
						style={{ minWidth: '8rem' }}
					/>
					<Column
						header="Adjusted Start"
						field="time_on"
						filterField="time_on"
						style={{ minWidth: '8rem' }}
					/>

					<Column
						field="actual_finish"
						header="Actual Finish"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '8rem' }}
					/>
					<Column
						field="time_off"
						header="Adjusted Finish"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '8rem' }}
					/>
					<Column
						field="total_hours"
						header="Total Hours"
						// body={(row) => {
						// 	return calcHours(row.time_on, row.time_off, row.lunch_break)
						// }}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						header="Lunch Break"
						field="lunch_break"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						field="comments"
						header="Comments"
						bodyClassName="p-text-center"
						style={{ minWidth: '10rem' }}
						body={(row) => {
							const comments = row.comments.split('\n').map((line: any) => (
								<>
									{line}
									<br />
								</>
							))
							return comments
						}}
					/>
					<Column
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
					/>
					<Column
						field="details"
						header="Operations"
						style={{ width: '7rem' }}
						body={(rowData) => (
							<div className="flex-row space-y-2 mt-2 mb-2 items-start">
								<Link
									to={AppRoutes.privateRoutes.TimesheetDetails.replace(
										':id',
										rowData.id
									)}
									className="flex items-center hover:bg-gray-300 pr-3 rounded-md hover:text-blue-600">
									<ClipboardIcon className="h-4 w-4 mx-2" />
									<span>Details {rowData.job_num}</span>
								</Link>
								<div className="flex items-center">
									<button
										onClick={() => {
											setTimesheetId(rowData.id)
											setOpenTimesheetEditForm(true)
										}}
										className="flex items-center hover:bg-gray-300 pr-3 rounded-md hover:text-yellow-600">
										<PencilIcon className="h-4 w-4 mx-2" />
										<span>Edit</span>
									</button>
								</div>
								<button
									onClick={async () => {
										if (
											window.confirm(
												'Are you sure you want to delete this timesheet?'
											)
										) {
											await deleteTimesheet(rowData.id)
										}
									}}
									className="flex items-center hover:bg-gray-300 pr-3 rounded-md hover:text-red-600">
									<XMarkIcon className="h-4 w-4 mx-2" />
									Delete
								</button>
							</div>
						)}
					/>
				</DataTable>
			</Container>
			<EditTimesheet
				timesheet_id={timesheetId}
				heading="Edit Timesheet"
				setOpen={setOpenTimesheetEditForm}
				open={openTimesheetEditForm}
			/>
			<CreateTimesheet
				heading="Create Timesheet"
				setOpen={setOpenTimesheetCreateForm}
				open={openTimesheetCreateForm}
			/>
		</div>
	)
}
