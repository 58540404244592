import {
	Checkbox,
	DateSelect,
	Dropdown,
	Input,
	MultiSelect,
	SideModal,
	Spinner,
	TextArea,
	TimeSelect,
} from 'common'
import { useFormik } from 'formik'
import { visitTypeOptions } from 'models'
import moment from 'moment'
import { useEffect } from 'react'
import {
	JobsServices,
	StaffServices,
	TimesheetServices,
	VisitTimesheetsServices,
} from 'services'
import {
	OptionsForDropdown,
	OptionsForDropdownArray,
	OptionsForDropdownFilter,
} from 'utilities'
import * as Yup from 'yup'

interface IProps {
	timesheet_id?: number
	heading: string
	setOpen: (open: boolean) => void
	open: boolean
}
interface IInitialValues {
	job_id: number
	visit_id: number
	staff_ids: number[]
	staff_id: number
	job_start_time: string
	time_in: string
	time_off: string
	date: string
	status: string
	task_ids: number[]
	manual_timesheet_visit_type: string
	had_lunch_break: boolean
	earning_rate: string
	should_update_payroll_timesheet: boolean
	notes: string
}

export const VisitTimesheetForm = ({
	timesheet_id,
	heading,
	setOpen,
	open,
}: IProps) => {
	const { data: visitTimesheetData, isLoading: visitTimesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheetsById(timesheet_id)

	const { data: timesheetData, isLoading: timesheetDataIsLoading } =
		TimesheetServices.useTimesheetsByStatus('Pending')

	const { data: allVisitTimesheets, isLoading: allVisitTimesheetsLoading } =
		VisitTimesheetsServices.useVisitTimesheets()

	const { data: staffData, isLoading: staffLoading } = StaffServices.useStaff()

	const { data: tasksData, isLoading: tasksLoading } = JobsServices.useTask()

	const { data: jobData, isLoading: jobsLoading } = JobsServices.useJobs()

	const jobDataSorted = jobData?.sort((a: any, b: any) => b.id - a.id)

	const { updateTimesheet } = TimesheetServices.useUpdateTimesheetById()
	const { updateVisitTimesheet } =
		VisitTimesheetsServices.useUpdateTimesheetById()
	const { createVisitTimesheet } =
		VisitTimesheetsServices.useCreateVisitTimesheet()
	console.log('tsData', visitTimesheetData)

	const filteredVisitTimesheets = allVisitTimesheets
		?.filter(
			(timesheet: { staff_id: any; date: any; status: string }) =>
				String(timesheet?.staff_id) === String(visitTimesheetData?.staff_id) &&
				timesheet.date === visitTimesheetData?.date && timesheet.status !== 'Deleted'
		)
		?.sort((a: { id: number }, b: { id: number }) => a.id - b.id)

	const timesheet = timesheetData?.find(
		(timesheet: { staff_id: any; date: any }) =>
			String(timesheet?.staff_id) === String(visitTimesheetData?.staff_id) &&
			timesheet.date === visitTimesheetData?.date
	)

	const mappedVisitTimesheets =
		filteredVisitTimesheets?.map(
			(
				visitTimesheet: { job_start_time: any; time_in: any },
				index: number
			) => {
				if (!visitTimesheet.job_start_time) {
					visitTimesheet.job_start_time =
						index === 0
							? timesheet?.time_on || visitTimesheet.time_in
							: filteredVisitTimesheets[index - 1].time_off
				}
				return { ...visitTimesheet, is_last_timesheet: false }
			}
		) || []
	
	// if (mappedVisitTimesheets.length > 0 && timesheet?.time_off) {
	// 	console.log('Time before:', mappedVisitTimesheets[mappedVisitTimesheets.length - 1].time_off)
	// 	mappedVisitTimesheets[mappedVisitTimesheets.length - 1].time_off =
	// 		timesheet.time_off
	// 	console.log('Time after:', mappedVisitTimesheets[mappedVisitTimesheets.length - 1].time_off)
	// }

	const visitTimesheetToBeEdited = mappedVisitTimesheets.find(
		(visitTimesheet: { id: any }) =>
			visitTimesheet.id === visitTimesheetData?.id
	)

	const initialValues: IInitialValues = {
		job_id: visitTimesheetData?.job_id || null,
		visit_id: visitTimesheetData?.visit_id || null,
		staff_id: visitTimesheetData?.staff_id || null,
		staff_ids: visitTimesheetData?.staff_ids || null,
		job_start_time: visitTimesheetToBeEdited?.job_start_time || null,
		time_in: visitTimesheetData?.time_in || null,
		time_off: visitTimesheetToBeEdited?.time_off || null,
		date: visitTimesheetData?.date || moment().format('DD/MM/YYYY'),
		status: visitTimesheetData?.status || 'Active',
		task_ids: visitTimesheetData?.task_ids || [],
		manual_timesheet_visit_type:
			visitTimesheetData?.manual_timesheet_visit_type || null,
		had_lunch_break: visitTimesheetData?.had_lunch_break || false,
		earning_rate: visitTimesheetData?.earning_rate || 'Ordinary Time',
		should_update_payroll_timesheet: visitTimesheetToBeEdited?.time_off !== visitTimesheetData?.time_off,
		notes: visitTimesheetData?.notes || '',
	}

	const validationSchema = Yup.object({
		job_id: Yup.number().required('Job is required'),
		time_in: Yup.string().required('Time on is required'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			if (timesheet_id) {
				console.log('Updating')
				const payload = {
					id: timesheet_id,
					job_id: values.job_id,
					visit_id: values.visit_id,
					staff_id: values.staff_id,
					job_start_time: values.job_start_time,
					time_in: values.time_in,
					time_off: values.time_off,
					date: values.date,
					status: values.status,
					task_ids: values.task_ids,
					manual_timesheet_visit_type: values.manual_timesheet_visit_type,
					had_lunch_break: values.had_lunch_break,
					earning_rate: values.earning_rate,
					notes: values.notes,
				}
				updateVisitTimesheet(payload)
				if (values.should_update_payroll_timesheet)
				{
					const newTs = {...timesheet}
					delete newTs.staffData
					updateTimesheet({...newTs,
						time_off: values.time_off,
					}, timesheet.id)
				}
			} else {
				console.log('Creating')
				const payload = [
					{
						job_id: values.job_id,
						visit_id: null,
						staff_ids: values.staff_ids,
						job_start_time: values.time_in,
						time_in: values.time_in,
						time_off: values.time_off,
						date: values.date,
						status: values.status,
						task_ids: values.task_ids,
						manual_timesheet_visit_type: values.manual_timesheet_visit_type,
						had_lunch_break: values.had_lunch_break,
						earning_rate: values.earning_rate,
						notes: values.notes,
					},
				]
				createVisitTimesheet(payload)
			}
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	useEffect(() => {
		formik.resetForm()
	}, [open])

	if (
		timesheet_id &&
		(visitTimesheetLoading || staffLoading || jobsLoading || tasksLoading)
	) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={timesheet_id ? 'Update Visit Timesheet' : heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType={timesheet_id ? 'update' : 'create'}>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<DateSelect
							title="Date"
							id="date"
							value={formik.values?.date}
							onChange={formik.setFieldValue}
							error={formik.errors.date}
						/>
					</div>
					<div className="w-1/2">
						<Dropdown
							id="job_id"
							label="Job"
							options={
								timesheet_id
									? OptionsForDropdownArray(jobDataSorted, 'id', [
											'job_num',
											'client_name',
											'descriptionOfQuote',
									  ])
									: OptionsForDropdownFilter(
											jobDataSorted,
											'id',
											['job_num', 'client_name', 'descriptionOfQuote'],
											'job_status',
											'In Progress'
									  )
							}
							value={`${formik.values.job_id}`}
							onChange={formik.setFieldValue}
							error={formik.errors.job_id}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<TimeSelect
							id={timesheet_id ? 'job_start_time' : 'time_in'}
							title="Time On"
							onChange={formik.setFieldValue}
							value={
								timesheet_id
									? formik.values.job_start_time
									: formik.values.time_in
							}
							error={
								timesheet_id
									? formik.errors.job_start_time
									: formik.values.time_in
							}
						/>
					</div>
					<div className="w-1/2">
						<TimeSelect
							id="time_off"
							title="Time Off"
							onChange={formik.setFieldValue}
							value={formik.values.time_off}
							error={formik.errors.time_off}
						/>
					</div>
				</div>
				{timesheet_id ? (
					<div className="flex items-center justify-between px-2">
						<div className="w-full">
							<Dropdown
								id="staff_id"
								label="Staff Member"
								options={OptionsForDropdown(staffData, 'id', 'staff_name')}
								value={formik.values.staff_id}
								onChange={formik.setFieldValue}
								error={formik.errors.staff_id}
							/>
						</div>
					</div>
				) : (
					<div className="flex items-center justify-between px-2">
						<div className="w-full">
							<MultiSelect
								id="staff_ids"
								label="Staff Members"
								options={OptionsForDropdownFilter(
									staffData,
									'id',
									['staff_name'],
									'status',
									'Active'
								)}
								value={formik.values.staff_ids}
								onChange={formik.setFieldValue}
								error={formik.errors.staff_ids}
							/>
						</div>
					</div>
				)}
				<div className="flex flex-col justify-between px-2">
					<div className="w-2/3">
						<Dropdown
							id="manual_timesheet_visit_type"
							label="Manual Visit Timesheet Type (For manual visit timesheets only)"
							options={visitTypeOptions}
							value={formik.values.manual_timesheet_visit_type}
							onChange={formik.setFieldValue}
							error={formik.errors.manual_timesheet_visit_type}
						/>
					</div>
				</div>

				<div className="flex flex-col justify-between px-4 py-8">
					<div className="w-1/2">
						<div className="flex items-center h-5">
							<input
								id={'had_lunch_break'}
								aria-describedby="comments-description"
								name={'had_lunch_break'}
								onChange={(e) => {
									formik.setFieldValue(
										'had_lunch_break',
										!formik.values.had_lunch_break
									)
								}}
								checked={formik.values.had_lunch_break}
								type="checkbox"
								className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"
							/>
							<div className="ml-3 text-sm">
								<label
									htmlFor={'had_lunch_break'}
									className="font-medium text-gray-700">
									Had Lunch Break?
								</label>
							</div>
						</div>
					</div>
				</div>
				<div className="flex flex-col justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="earning_rate"
							label="Earning Rate"
							options={[
								{ value: 'Ordinary Time', label: 'Ordinary Time' },
								{ value: '1.5', label: '1.5 Time' },
								{ value: '1.2', label: '1.2 Time' },
							]}
							value={formik.values.earning_rate}
							onChange={formik.setFieldValue}
							error={formik.errors.earning_rate}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<Dropdown
							id="status"
							label="Status"
							options={[
								{ value: 'Active', label: 'Active' },
								{ value: 'Inactive', label: 'Inactive' },
								{ value: 'Completed', label: 'Completed' },
								{ value: 'Approved', label: 'Approved' },
							]}
							value={formik.values.status}
							onChange={formik.setFieldValue}
							error={formik.errors.status}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<MultiSelect
							id="task_ids"
							label="Tasks"
							options={OptionsForDropdownFilter(
								tasksData,
								'id',
								['zone_label', 'type', 'description'],
								'job_id',
								formik.values.job_id
							)}
							onChange={formik.setFieldValue}
							value={formik.values.task_ids}
							error={formik.errors.task_ids}
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<TextArea
							id="notes"
							title="Notes"
							rows={4}
							type="text"
							error={formik.errors.notes}
							value={formik.values.notes}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
