import { Input, SideModal, Spinner, TextArea, TimeSelect } from 'common'
import { useFormik } from 'formik'
import { TimesheetServices } from 'services'
import * as Yup from 'yup'

interface IProps {
	timesheet_id: number | null
	heading: string
	setOpen: (open: boolean) => void
	open: boolean
}

interface IInitialValues {
	time_on: string
	time_off: string
	comments: string
	lunch_break: number
}

export const EditTimesheet = ({
	timesheet_id,
	heading,
	setOpen,
	open,
}: IProps) => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		TimesheetServices.useTimesheetById(timesheet_id)

	const { updateTimesheet } = TimesheetServices.useUpdateTimesheetById()

	const initialValues: IInitialValues = {
		time_on: timesheetData?.time_on || '',
		time_off: timesheetData?.time_off,
		comments: timesheetData?.comments || '',
		lunch_break: timesheetData?.lunch_break || 0,
	}

	const validationSchema = Yup.object({
		lunch_break: Yup.number()
			.required('Lunch Break is required')
			.typeError('Lunch Break must be a number'),
	})

	const formik = useFormik({
		initialValues,
		validationSchema,
		enableReinitialize: true,
		onSubmit: async (values, { setSubmitting }) => {
			const payload = [
				{
					id: timesheet_id,
					comments: values.comments,
					time_on: values.time_on,
					time_off: values.time_off,
					lunch_break: values.lunch_break,
				},
			]

			updateTimesheet(payload)
			setSubmitting(false)
			formik.resetForm()
			setOpen(false)
		},
	})

	if (!timesheet_id && timesheetLoading) {
		return <Spinner />
	}

	return (
		<>
			<SideModal
				heading={heading}
				open={open}
				setOpen={setOpen}
				handleSubmit={formik.handleSubmit}
				isLoading={formik.isSubmitting}
				formType="update">
				<div className="flex items-center justify-between px-2">
					<div className="w-1/2">
						<TimeSelect
							id="time_on"
							title="Adjusted Start"
							onChange={formik.setFieldValue}
							value={formik.values.time_on}
							error={formik.errors.time_on}
							isClearable
						/>
					</div>
					<div className="w-1/2">
						<TimeSelect
							id="time_off"
							title="Adjusted Finish"
							onChange={formik.setFieldValue}
							value={formik.values.time_off}
							error={formik.errors.time_off}
							isClearable
						/>
					</div>
				</div>
				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<Input
							id="lunch_break"
							title="Lunch Break"
							placeholder="Lunch Break"
							type="text"
							error={formik.errors.lunch_break}
							value={formik.values.lunch_break}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>

				<div className="flex items-center justify-between px-2">
					<div className="w-full">
						<TextArea
							id="comments"
							title="Comments"
							rows={4}
							type="text"
							error={formik.errors.comments}
							value={formik.values.comments}
							handleChange={formik.handleChange}
							handleBlur={formik.handleBlur}
						/>
					</div>
				</div>
			</SideModal>
		</>
	)
}
