import { useEffect, useState } from 'react'
import { DataTable } from 'primereact/datatable'
import {
	TimesheetServices,
	DataTableServices,
	VisitTimesheetsServices,
	StaffServices,
	VisitServices,
	JobsServices,
} from 'services'
import { Badge, Container, DataTableHeader, PageHeading, Spinner } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { ApproveVisitTimesheet } from 'components/VisitTimesheets'
import { FilterMatchMode, FilterOperator } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'
import { EditTimesheet } from 'components/Timesheets/EditForm'
import { VisitTimesheetForm } from 'components/VisitTimesheets'
import { PencilIcon } from '@heroicons/react/24/outline'
import { ColumnGroup } from 'primereact/columngroup'
import { Row } from 'primereact/row'
import { CheckIcon, XMarkIcon } from '@heroicons/react/24/solid'

export const VisitsBasedTimesheetsPage = () => {
	const { data: timesheetData, isLoading: timesheetLoading } =
		VisitTimesheetsServices.useVisitTimesheets()
	const { data, isLoading } = TimesheetServices.useTimesheets()
	const { updateVisitTimesheet } =
		VisitTimesheetsServices.useUpdateTimesheetById()
	const { data: visitsData, isLoading: visitsLoading } =
		VisitServices.useVisits()
	const [openVisitTimesheetForm, setOpenVisitTimesheetForm] = useState(false)
	const [timesheetId, setTimesheetId] = useState(undefined)
	const [timesheetsSelected, setTimesheetsSelected] = useState([])
	const [generatedData, setGeneratedData] = useState<any>([])
	const { data: taskData, isLoading: taskLoading } = JobsServices.useTask()

	const deleteTimesheet = async (timesheetId: number) => {
		await updateVisitTimesheet({ id: timesheetId, status: 'Deleted' })
	}

	const getVisitTaskName = (task_ids: string[]) => {
		if (!taskData) return 'None'
		const taskNames = task_ids
			.map((task_id) => {
				const task = taskData.find((t: any) => t.id === task_id)
				return task
					? `${task.zone_label} - ${task.type} - ${task.description}`
					: null
			})
			.filter((taskName) => taskName !== null)
		return taskNames.length ? taskNames.join(', ') : 'None'
	}

	useEffect(() => {
		if (!timesheetLoading && !isLoading && !taskLoading) {
			const filteredTimesheets = [...data]?.filter((timesheet: any) => {
				if (timesheet?.status !== 'Pending - Deleted') {
					return timesheet
				}
			})

			const findStartTime = (staffId: number, date: string) => {
				const entry = filteredTimesheets.find(
					(item: { staff_id: number; date: string }) =>
						item.staff_id === staffId && item.date === date
				)
				return entry ? entry.time_on : null
			}

			const findEndTime = (staffId: number, date: string) => {
				const entry = filteredTimesheets.find(
					(item: { staff_id: number; date: string }) =>
						item.staff_id === staffId && item.date === date
				)
				return entry ? entry.time_off : null
			}

			const sortAndGroupEntries = (data: any) => {
				const groupedEntries: any = {}

				data.forEach((item: { staff: { id: any }; date: any }) => {
					const key = `${item.staff.id}_${item.date}`
					if (!groupedEntries[key]) {
						groupedEntries[key] = []
					}
					groupedEntries[key].push(item)
				})

				Object.keys(groupedEntries).forEach((key) => {
					groupedEntries[key].sort(
						(
							a: { job_start_time: moment.MomentInput },
							b: { job_start_time: moment.MomentInput }
						) => {
							const aTimeIn = moment(a.job_start_time, 'HH:mm').valueOf()
							const bTimeIn = moment(b.job_start_time, 'HH:mm').valueOf()
							return aTimeIn - bTimeIn
						}
					)
				})

				return groupedEntries
			}

			const updateTimeForMultipleEntries = (groupedEntries: any) => {
				Object.keys(groupedEntries).forEach((key) => {
					const entries = groupedEntries[key]
					for (let i = 0; i < entries.length; i++) {
						if (!entries[i].job_start_time) {
							if (i === 0) {
								const lookedUpStartTime = findStartTime(
									entries[i].staff.id,
									entries[i].date
								)
								entries[i].job_start_time =
									lookedUpStartTime || entries[i].time_in
							} else {
								entries[i].job_start_time = entries[i - 1].time_off
							}
						}

						if (i === entries.length - 1) {
							if (entries[i].time_off) {
								const lookedUpEndTime = findEndTime(
									entries[i].staff.id,
									entries[i].date
								)
								if (lookedUpEndTime) {
									entries[i].time_off = lookedUpEndTime
								}
							}
						}
					}
				})

				return Object.values(groupedEntries).flat()
			}

			const filteredVisitTimesheets = [...timesheetData]?.filter(
				(visitTimesheet: any) => {
					if (
						visitTimesheet?.status !== 'Deleted' &&
						visitTimesheet?.status !== 'Inactive'
					) {
						return visitTimesheet
					}
				}
			)

			const groupedEntries = sortAndGroupEntries(filteredVisitTimesheets)

			const updatedTimesheetData: any =
				updateTimeForMultipleEntries(groupedEntries)

			if (updatedTimesheetData.length > 0 && data.time_off) {
				updatedTimesheetData[updatedTimesheetData.length - 1].time_off =
					timesheetData.time_off
			}

			const rowData = updatedTimesheetData
				?.filter(
					(timesheet: { status: string; job: { status: string } }) =>
						(timesheet?.job?.status === 'Active' ||
							timesheet?.job?.status === 'Approved') &&
						timesheet?.status !== 'Deleted' &&
						timesheet?.status !== 'Inactive'
				)
				?.map((timesheet: any) => {
					const visit: any = timesheet.visit
					const job: any = timesheet?.job
					const staff: any = timesheet?.staff

					const start_time = timesheet.job_start_time
						? moment(timesheet.job_start_time, 'HH:mm')
						: moment(timesheet.time_in, 'HH:mm')
					const end_time = moment(timesheet.time_off, 'HH:mm')
					const difference = end_time.diff(start_time, 'hours', true) || 0
					if(timesheet?.id == 2550)
					{
						console.log('yay')
					}
					return {
						record_id: timesheet?.id,
						staff_id: staff?.id,
						id:
							String(job?.id) +
							'_' +
							String(visit?.id) +
							'_' +
							String(staff?.id) +
							'_' +
							String(timesheet?.id),
						visit_id: visit?.id,
						name: staff?.staff_name,
						job_start_time: timesheet.job_start_time || timesheet?.time_in,
						pre_start_time: timesheet?.time_in,
						end_time: timesheet?.time_off,
						date: timesheet?.date,
						formattedDate: moment(timesheet?.date, 'DD/MM/YYYY').toDate(),
						job: job?.job_num,
						description: job?.descriptionOfQuote,
						client_name: job?.clientData?.client_name,
						site: job?.site,
						hours: difference,
						confined_spaces: job?.job_type === 'Confined Spaces' ? true : false,
						timesheet_status: timesheet?.status,
						timestamp: timesheet.createdAt
							? moment(timesheet.createdAt).toDate().getTime()
							: -1,
						task_ids: visit ? visit.task_ids : timesheet.task_ids || [],
						visit_type: visit
							? visit.type
							: timesheet?.manual_timesheet_visit_type
							? timesheet.manual_timesheet_visit_type
							: 'Other',
						task_list: getVisitTaskName(
							visit ? visit.task_ids : timesheet.task_ids || []
						),
						earning_rate: timesheet?.earning_rate,
						had_lunch_break: timesheet?.had_lunch_break,
						notes: timesheet?.notes,
					}
				})

			setGeneratedData(rowData)
		}
	}, [timesheetData, timesheetLoading, data, isLoading, taskData, taskLoading])
	// console.log(generatedData.filter((timesheet: any) => timesheet?.record_id == 2550))
	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
		FilterColumn,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'timesheet_status',
				filterInitialValue: ['Approved', 'Inactive'],
				filterInitialMatchMode: FilterMatchMode.NOT_EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [
					'Active',
					'Inactive',
					'Completed',
					'Approved',
					'Pending Close Of Visit',
				],
				typeFilterOperator: FilterOperator.AND,
			},
			{
				filterName: 'formattedDate',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.DATE_IS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.date,
				filterOptions: [],
				typeFilterOperator: FilterOperator.AND,
			},
			{
				filterName: 'name',
				filterInitialValue: null,
				filterInitialMatchMode: FilterMatchMode.CONTAINS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.containsOrNot,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: [
			'job',
			'site',
			'visit_id',
			'status',
			'client_name',
			'task_list',
			'visit_type',
			'description',
			'date',
			'job_start_time',
			'pre_start_time',
			'end_time',
			'hours',
			'timesheet_status',
			'confined_spaces',
		],
	})

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
		visit_id: any
	}) => {
		let total = 0

		if (generatedData) {
			for (const timesheet of generatedData) {
				if (
					Number(timesheet.visit_id) === Number(row.visit_id) &&
					timesheet.status !== 'Pending' &&
					Number(timesheet.hours) >= 0
				) {
					const hours = Number(timesheet.hours)
					total += hours
				}
			}
		}
		return total
	}

	const headerTemplate = (data: { visit_id: number; date: string }) =>
		data.visit_id ? (
			<span className="text-gray-900 font-bold">
				Visit #{data?.visit_id} on {data?.date}
			</span>
		) : (
			<span className="text-gray-900 font-bold">No Visits</span>
		)

	const footerTemplate = (data: any) =>
		data.visit_id ? (
			<>
				<td
					colSpan={12}
					style={{ textAlign: 'right' }}
					className="bg-gray-100 font-normal">
					Total Hours
				</td>
				<td colSpan={1} className="bg-gray-100 font-semibold">
					{calculateTimesheetTotal(data)}
				</td>
			</>
		) : (
			<></>
		)

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	useEffect(() => {
		if (!openVisitTimesheetForm) {
			setTimesheetId(undefined)
		}
	}, [openVisitTimesheetForm])

	const isSelectable = (data: {
		end_time: string
		timesheet_status: string
	}) => {
		return data?.end_time?.length >= 0 && data?.timesheet_status === 'Completed'
	}

	const isRowSelectable = (event: any) =>
		event.data ? isSelectable(event.data) : true

	if (timesheetLoading || isLoading || visitsLoading) {
		return <Spinner />
	}

	// console.log({ generatedData })

	return (
		<div className="card">
			<PageHeading
				title="Visit Timesheets"
				createBtn={'Create Visit Timesheet'}
				isEditable={false}
				setOpen={setOpenVisitTimesheetForm}
			/>
			<Container>
				<div className="flex justify-between ml-4">
					<ApproveVisitTimesheet timesheets={timesheetsSelected} />
				</div>
				<DataTable
					ref={dataTableReference}
					value={generatedData}
					loading={timesheetLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					groupRowsBy="visit_id"
					dataKey="id"
					filters={filters}
					globalFilterFields={globalFilterFields}
					rowGroupMode="subheader"
					rowGroupHeaderTemplate={headerTemplate}
					rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					sortOrder={-1}
					sortField="timestamp"
					header={header}
					resizableColumns={true}
					selection={timesheetsSelected}
					isDataSelectable={isRowSelectable}
					stripedRows
					onSelectionChange={(e) => setTimesheetsSelected(e.value as any)}
					emptyMessage="No Timesheets found.">
					<Column
						selectionMode="multiple"
						headerStyle={{
							width: '3rem',
							display: 'flex',
							alignItems: 'center',
							paddingTop: '0.5rem',
							marginTop: '-0.5px',
							paddingBottom: '0.5rem',
							marginBottom: '-0.5px',
							marginLeft: '-0.5px',
							justifyContent: 'center',
						}}
						bodyClassName={(row: any) => {
							if (row.timesheet_status === 'Approved') {
								return 'text-sm bg-green-200'
							}
							return 'text-sm bg-orange-200'
						}}
						bodyStyle={{
							alignItems: 'center',
							justifyContent: 'center',
						}}
					/>
					<Column
						field="name"
						header="Staff"
						style={{ minWidth: '5rem' }}
						filter
						showFilterOperator={false}
						filterPlaceholder="Search by name"
					/>
					<Column
						field="visit_id"
						header="Visit #"
						style={{ minWidth: '2rem' }}
						bodyClassName={'text-sm'}
					/>
					<Column
						field="job"
						header="Job #"
						style={{ minWidth: '2rem' }}
						bodyClassName={'text-sm'}
					/>
					<Column
						field="client_name"
						header="Client"
						style={{
							minWidth: '2rem',
							maxWidth: '10rem',
							whiteSpace: 'normal',
						}}
						bodyClassName={'text-sm'}
					/>
					<Column
						header="Task"
						field="task_list"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '10rem', whiteSpace: 'normal' }}
						body={(row) => {
							const tasks = row.task_list.split(', ').map((task: any) => (
								<span>
									{task}
									<br />
								</span>
							))
							return tasks
						}}
					/>
					<Column
						header="Type"
						field="visit_type"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '10rem' }}
					/>
					<Column
						field="description"
						header="Description"
						style={{ minWidth: '2rem', maxWidth: '10rem', whiteSpace: 'normal' }}
						bodyClassName={'text-sm'}
					/>

					{/* <Column
						field="site"
						header="Site"
						style={{ minWidth: '5rem' }}
					/> */}
					<Column
						sortable
						header="Date"
						field="formattedDate"
						style={{ minWidth: '5rem' }}
						bodyClassName={'text-sm'}
						body={(row) => (
							<div>
								{row.date}
							</div>
						)}
						{...FilterColumn.formattedDate}
					/>
					<Column
						header="Job Start"
						field="job_start_time"
						style={{ minWidth: '4rem' }}
						bodyClassName={'text-sm'}
					/>
					<Column
						header="Pre Start"
						field="pre_start_time"
						style={{ minWidth: '4rem' }}
						bodyClassName={'text-sm'}
					/>
					<Column
						header="Time Off"
						field="end_time"
						style={{ minWidth: '4rem' }}
						bodyClassName={'text-sm'}
					/>
					<Column
						header="Hours"
						field="hours"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
						bodyClassName={'text-sm'}
					/>
					{/* <Column
						header="Had Lunch?"
						field="had_lunch_break"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '8rem' }}
						body={(row) =>
							row.had_lunch_break ? (
								<span className="flex items-center justify-center">
									Yes
									<CheckIcon className="h-4 w-4 ml-1" />
								</span>
							) : (
								<span className="flex items-center justify-center">
									No
									<XMarkIcon className="h-4 w-4 ml-1" />
								</span>
							)
						}
					/> */}
					<Column
						field="notes"
						header="Notes"
						style={{ minWidth: '2rem', maxWidth: '10rem', whiteSpace: 'normal' }}
						bodyClassName={'text-sm'}
					/>
					<Column
						header="Earning Rate"
						field="earning_rate"
						style={{ minWidth: '5rem' }}
					/>
					<Column
						{...FilterColumn.timesheet_status}
						header="Timesheet Status"
						field="timesheet_status"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '8rem' }}
						body={(row) => (
							<Badge type={row.timesheet_status} text={row.timesheet_status} />
						)}
					/>
					<Column
						header="Confined Spaces?"
						field="confined_spaces"
						bodyClassName="p-text-center text-sm"
						style={{ maxWidth: '8rem' }}
						body={(row) =>
							row.confined_spaces ? (
								<Badge type={'Warning'} text={'Confined Space'} />
							) : (
								<Badge type={'Green'} text={'No'} />
							)
						}
					/>
					<Column
						field="details"
						header="Operations"
						style={{ width: '7rem' }}
						bodyClassName="p-text-center text-sm"
						body={(rowData) => (
							<div className="flex-row space-y-2 mt-2 mb-2 items-start">
								{1 === 1 || rowData.timesheet_status !== 'Approved' ? (
									<button
										onClick={() => {
											setTimesheetId(rowData.record_id)
											setOpenVisitTimesheetForm(true)
										}}
										className="flex items-center hover:bg-gray-300 pr-3 rounded-md hover:text-yellow-600">
										<PencilIcon className="h-4 w-4 mx-2" />
										<span>Edit</span>
									</button>
								) : null}
								<button
									onClick={async () => {
										if (
											window.confirm(
												'WARNING: DELETION \r\nAre you sure you want to delete this visit timesheet?'
											)
										) {
											await deleteTimesheet(rowData.record_id)
										}
									}}
									className="flex items-center hover:bg-gray-300 pr-3 rounded-md hover:text-red-600">
									<XMarkIcon className="h-4 w-4 mx-2" />
									Delete
								</button>
							</div>
						)}
					/>
				</DataTable>
			</Container>
			<VisitTimesheetForm
				timesheet_id={timesheetId}
				heading="Create Visit Timesheet"
				setOpen={setOpenVisitTimesheetForm}
				open={openVisitTimesheetForm}
			/>
		</div>
	)
}
